import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container, Image } from "react-bootstrap";
import Heading from "../Components/Heading";
import cityCollege from "../img/clients/cityCollege.png";
import punjabPolice from "../img/clients/punjabPolice.png";
import traficPolice from "../img/clients/traficPolice.png";
import traficPoliceMultan from "../img/clients/traficPoliceMultan.png";
import khidmatcenter from "../img/clients/khidmatcenter.png";

const ClientCarousel = () => {
  const slickSettings = {
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    arrows: false,
    dots: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  return (
    <section>
      <Container>
        <div className="text-center">
          <Heading title="Our Clients" heading="Client-Centric Excellence" />
        </div>
        <Slider className="customer-logos" {...slickSettings}>
          <div className="slide">
            <Image src={khidmatcenter} thumbnail />
          </div>
          <div className="slide">
            <Image src={punjabPolice} thumbnail />
          </div>
          <div className="slide">
            <Image src={traficPolice} thumbnail />
          </div>
          <div className="slide">
            <Image src={traficPoliceMultan} thumbnail />
          </div>
          <div className="slide">
            <Image src={cityCollege} thumbnail />
          </div>
          <div className="slide">
            <Image src={punjabPolice} thumbnail />
          </div>
          <div className="slide">
            <Image src={traficPolice} thumbnail />
          </div>
          <div className="slide">
            <Image src={traficPoliceMultan} thumbnail />
          </div>
        </Slider>
      </Container>
    </section>
  );
};

export default ClientCarousel;

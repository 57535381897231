import Header from "../Components/Header2";
import { Row, Col, Container, Card, Image } from "react-bootstrap";
import cityCollege from "../img/clients/cityCollege.png";
import punjabPolice from "../img/clients/punjabPolice.png";
import traficPolice from "../img/clients/traficPolice.png";
import traficPoliceMultan from "../img/clients/traficPoliceMultan.png";
import khidmatcenter from "../img/clients/khidmatcenter.png";
function Clients() {
  return (
    <>
      <Header title="Clients" />
      <section className="clients">
        <Container>
          <Row className="justify-content-md-center">
            <Col xs={6} md={3} className="mt-4">
              <Card >
                <Card.Body>
                  <Image src={cityCollege} fluid />
                </Card.Body>
              </Card>
            </Col>
            <Col xs={6} md={3} className="mt-4">
              <Card >
                <Card.Body>
                <Image src={punjabPolice} fluid />
                </Card.Body>
              </Card>
            </Col>
            <Col xs={6} md={3} className="mt-4">
              <Card >
                <Card.Body>
                <Image src={traficPolice} fluid />
                </Card.Body>
              </Card>
            </Col>
            <Col xs={6} md={3} className="mt-4">
              <Card >
                <Card.Body>
                <Image src={traficPoliceMultan} fluid />
                </Card.Body>
              </Card>
            </Col>
            <Col xs={6} md={3} className="mt-4">
              <Card >
                <Card.Body>
                <Image src={khidmatcenter} fluid />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
export default Clients;
import {Row, Container } from "react-bootstrap";
import CustomCard from "./CustomCard";
import Header from "./Header2";
import QmaticDetail from "./QmaticDetail";
import Clients from "./ClientsSlider";
import Heading from "./Heading";
import {
  faUserFriends,
  faTicket,
  faAddressCard,
  faComputer,
  faUserCog,
  faUsersCog,
  faChartBar,
  faCommentDots,
  faCalendarCheck,
  faPeopleLine,
  faExclamationCircle,
  faCogs,
  faDesktop,
  faGavel,
  faLink,
} from "@fortawesome/free-solid-svg-icons";
function Services() {
  return (
    <>
      <Header title="Services" />
      <section>
        <Container>
          <div className="row text-center ">
            <Heading
              title="Why Choose Us"
              heading="Transforming Wait Times into Quality Tim"
            />
            <p>
              It is a compelling concept that centers on turning what is
              traditionally seen as an inconvenience – waiting – into a positive
              and valuable experience for customers.
            </p>
          </div>
          <Row className="justify-content-md-center text-center">
            <CustomCard
              icon={faUserFriends}
              title="Creation and Management"
              bodyContent="QMS allows you to create and manage multiple queues or lines for different services or departments within an organization. It ensures that customers are directed to the right queue based on their needs."
            />
            <CustomCard
              icon={faTicket}
              title="Queue Ticketing System"
              bodyContent="Our QMS solutions include a ticketing system that assigns a unique ticket number to each customer when they arrive. Customers can monitor their position in the queue and receive notifications when it's their turn."
            />
            <CustomCard
              icon={faAddressCard}
              title="Customer Registration"
              bodyContent="Our systems offer customer registration capabilities, enabling customers to input their information or service request details at a self-service kiosk or online before joining the queue."
            />
            <CustomCard
              icon={faUserCog}
              title="Multi-Channel Integration"
              bodyContent="Integration with various communication channels like websites, mobile apps, SMS, or voice calls allows customers to check queue status, join a queue remotely, or receive notifications about their queue progress."
            />
            <CustomCard
              icon={faUsersCog}
              title="Staff Management"
              bodyContent="Our QMS System can help organizations allocate and manage staff resources efficiently by monitoring queue lengths and adjusting staffing levels in real-time."
            />
            <CustomCard
              icon={faComputer}
              title="Digital Signage"
              bodyContent="Digital displays or signage screens can be used to show queue information, estimated wait times, and announcements to keep customers informed."
            />
            <CustomCard
              icon={faChartBar}
              title="Analytics, Reporting"
              bodyContent="Our Systems often include reporting and analytics tools that help organizations analyze queue data, customer waiting times, and service efficiency to make data-driven decisions for improvement."
            />
            <CustomCard
              icon={faCommentDots}
              title="Customer Feedback"
              bodyContent="Our QMS solutions allow customers to provide feedback and ratings after their service is complete, providing valuable insights for quality improvement."
            />
            {/*  */}
            <CustomCard
              icon={faExclamationCircle}
              title="Priority Services"
              bodyContent="Our QMS can accommodate priority services for certain customers, such as senior citizens, disabled individuals, or VIPs, ensuring they receive prompt attention."
            />
            <CustomCard
              icon={faCalendarCheck}
              title="Appointment Scheduling"
              bodyContent="In addition to walk-in customers, QMS may support appointment scheduling to enable customers to book specific time slots for their visit."
            />
            <CustomCard
              icon={faPeopleLine}
              title="Queue Monitoring"
              bodyContent="Managers and staff can monitor queue statuses and make-adjustments in real-time to address bottlenecks or delays."
            />
            <CustomCard
              icon={faLink}
              title="Integration with Other"
              bodyContent="Our QMS solutions can integrate with other business systems like customer relationship management (CRM), inventory management, and appointment booking systems for a seamless customer experience."
            />
            <CustomCard
              icon={faGavel}
              title="Compliance and Security"
              bodyContent="We offer security features to protect customer data and ensure compliance with privacy regulations."
            />
            <CustomCard
              icon={faDesktop}
              title="Remote Management"
              bodyContent="In some cases, QMS systems can be managed remotely, allowing administrators to make changes or adjustments from a central location."
            />
            <CustomCard
              icon={faCogs}
              title="Customization"
              bodyContent="Our solution often customizable to meet the specific needs and branding of different organizations."
            />
          </Row>
        </Container>
      </section>
      <section className="bg-light-blue">
        <QmaticDetail />
      </section>
      <Clients />
    </>
  );
}
export default Services;
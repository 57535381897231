import { Row, Col, Card, Form, } from "react-bootstrap";
import Header from "../Components/Header2";
import {Container } from "react-bootstrap";
import Heading from "./Heading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GoogleMaps from "./GoogleMap";
import {
  faPhone,
  faMapLocation,
  faMapPin,
  faEnvelope,
  faHeadphonesAlt,
} from "@fortawesome/free-solid-svg-icons";
import ContactForm from "./ContactForm";
function ContactUs() {
  return (
    <>
      <Header title="Contact Us" />
      <section>
        <div className="text-center">
          <Heading
            title="Get in Touch"
            heading="Contact us for personalized assistance"
          />
          <p>Please feel free to contact us for any inquiries or assistance</p>
        </div>
        <Container>
          <Row className="mt-5 contact-form-main justify-content-center">
            <Col xs={12} md={9}>
              <Row>
                <Col xs={12} md={5}>
                  <h5>Quick Response to Your Query</h5>
                  <ul>
                    <li>
                      <FontAwesomeIcon className="icon" icon={faPhone} />{" "}
                      <span>
                        +92 32 60667666, <br></br>+92 300 8639171
                      </span>
                    </li>
                    <li>
                      <FontAwesomeIcon className="icon" icon={faEnvelope} />{" "}
                      <span>info@qmatic.com</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        className="icon"
                        icon={faHeadphonesAlt}
                      />{" "}
                      <span>sales@qmatic.com</span>
                    </li>
                    <li>
                      <FontAwesomeIcon className="icon" icon={faMapLocation} />{" "}
                      <span>327-F, Shah Ruken-E-Alam Colony, Multan</span>
                    </li>
                  </ul>
                </Col>
                <Col xs={12} md={7}>
                  <ContactForm/>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <GoogleMaps></GoogleMaps>
    </>
  );
}

export default ContactUs;

import React, { useState, useRef, useEffect } from "react";
import { Card, Row, Col, Modal, Image, Button } from "react-bootstrap";
import videoThumb from "../img/videoThumb.jpg";

const videos = [
  {
    id: 1,
    title: 'Qmatic Counter Connecting Guide',
    source: require('../videos/qmaticCounterConnectionGuide.mp4'),
  },
  {
    id: 2,
    title: 'Hujra Shah Muqeem Dipalpur',
    source: require('../videos/Qmatic-HujraShahMuqeem.mp4'),
  },
  {
    id: 3,
    title: 'Sadar Police Station Multan',
    source: require('../videos/Qmatic-PoliceStationMultan.mp4'),
  },
  {
    id: 4,
    title: 'Sadar Police Station Multan',
    source: require('../videos/Qmatic-PoliceStationSadarMultan.mp4'),
  },
];
export default function GuideVideos() {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const togglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  useEffect(() => {
    // Add an event listener to the document to handle clicks outside the video player.
    const handleClickOutside = (event) => {
      if (videoRef.current && !videoRef.current.contains(event.target)) {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleShow = (video) => {
    setSelectedVideo(video);
  };

  const handleClose = () => {
    setSelectedVideo(null);
    setIsPlaying(false);
  };

  return (
    <Row>
  {videos.map((video) => (
    <Col key={video.id} xs={12} md={4} className="mb-3">
      <Card>
        <Card.Body>
          <Image
            src={videoThumb}
            thumbnail
            onClick={() => handleShow(video)}
          />
          <Card.Title className="mt-2 mb-0">{video.title}</Card.Title>
        </Card.Body>
      </Card>
    </Col>
  ))}
  {selectedVideo && (
    <Modal show={!!selectedVideo} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          {selectedVideo.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <video
          ref={videoRef}
          controls
          autoPlay={true}
          muted={false}
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
          onError={(e) => console.error("Video Error:", e)}
          poster={videoThumb}
        >
          <source src={selectedVideo.source} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Modal.Body>
    </Modal>
  )}
</Row>
  );
}
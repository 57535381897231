import {Row, Col, Container, Button, Image } from "react-bootstrap";
import step1 from "../img/step1.png";
import step2 from "../img/step2.png";
import step3 from "../img/step3.png";
import step4 from "../img/step4.png";
import step5 from "../img/step5.png";
import chromeicon from "../img/chrome_icon.png";
function CounterGuide() {
  return (
    <>
    <div className="counterGuide">
    <section>
          <Container>
            <Row className="justify-content-center align-items-center">
              <Col xs={12} md={8}>
                <Row className="text-center mb-4">
                  <h3>How to Connect with Qmatic Counter</h3>
                  <p>
                    Connecting the counter involves a concise series of steps.
                  </p>
                </Row>
                <Row className="align-items-center">
                  <Col xs={12} md={6}>
                    <h3>Step <span>1</span></h3>
                    <p>
                      Your mobile must have at least Android version 12 to
                      connect your Internet WiFi router with the Qomatic counter
                      display.
                    </p>
                  </Col>
                  <Col xs={12} md={6}>
                    <Image
                      src={step1}
                      alt="Your mobile must have at least Android version 12 to connect your Internet WiFi router with the Qomatic counter display."
                      caption="Your mobile must have at least Android version 12 to connect your Internet WiFi router with the Qomatic counter display."
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        <section>
          <Container>
            <Row className="justify-content-center align-items-center">
              <Col xs={12} md={8}>
                <Row className="align-items-center">
                  <Col xs={12} md={6}>
                    <Image
                      src={step2}
                      alt="Your mobile must have at least Android version 12 to connect your Internet WiFi router with the Qomatic counter display."
                      caption="Your mobile must have at least Android version 12 to connect your Internet WiFi router with the Qomatic counter display."
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <h3>Step <span>2</span></h3>
                    <p>
                    Click or tap on "<span className="text-primary">Softagics_225</span>" in your mobile device's Wi-Fi search.
                    </p>
                    <p>
                      <span className="text-danger">Note:</span> WiFi search can
                      be anything from <b>Softagics_225</b> or <b>Softagics_225</b> to <b>Softagics_250</b>. Its
                      password is <span className="text-primary">12345678</span>
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        <section>
          <Container>
            <Row className="justify-content-center align-items-center">
              <Col xs={12} md={8}>
                <Row className="align-items-center">
                  <Col xs={12} md={6}>
                    <h3>Step <span>3</span></h3>
                    <p>
                    After connecting to "Softagics_225," open your web browser and type "192.168.1.225" in the address bar. Then, click on the "Configure WiFi" button.
                    </p>
                  </Col>
                  <Col xs={12} md={6} className="browser-select">
                    <Image
                      src={step3}
                      alt="Your mobile must have at least Android version 12 to connect your Internet WiFi router with the Qomatic counter display."
                      caption="Your mobile must have at least Android version 12 to connect your Internet WiFi router with the Qomatic counter display."
                    />
                    <div className="chromeicon">
                      <Image
                        src={chromeicon}
                        alt="Qmatic Counter"
                        caption="Qmatic Counter Softagics"
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        <section>
          <Container>
            <Row className="justify-content-center align-items-center">
              <Col xs={12} md={8}>
                <Row className="align-items-center">
                  <Col xs={12} md={6}>
                    <Image
                      src={step4}
                      alt="Your mobile must have at least Android version 12 to connect your Internet WiFi router with the Qomatic counter display."
                      caption="Your mobile must have at least Android version 12 to connect your Internet WiFi router with the Qomatic counter display."
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <h3>Step <span>4</span></h3>
                    <p>
                    Connect your Qmatic counter to your WiFi network and then click on the "Save" button.
                    </p>
                    <p>
                      <span className="text-danger">Note:</span> Don't change any IP Address
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        <section>
          <Container>
            <Row className="justify-content-center align-items-center">
              <Col xs={12} md={8}>
                <Row className="align-items-center">
                  <Col xs={12} md={6}>
                    <h3>Step <span>5</span></h3>
                    <p>
                    When this screen appears, it indicates that your Qmatic counter is connected to your WiFi router. The display shows "0225" on the first line and "qms.softagics.com" on the second line.
                    </p>
                  </Col>
                  <Col xs={12} md={6}>
                    <Image
                      src={step5}
                      alt="Your mobile must have at least Android version 12 to connect your Internet WiFi router with the Qomatic counter display."
                      caption="Your mobile must have at least Android version 12 to connect your Internet WiFi router with the Qomatic counter display."
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        </div>
        </>
  );
}

export default CounterGuide;

import { Form, Container, Row, Col, FloatingLabel, Stack, Button} from 'react-bootstrap';
import Heading from '../Components/Heading';
import ContactForm from "./ContactForm";
function ScheduleForm() {
    return (
<>
            <Heading title='Schedule A Demo' heading="Your Personal Demo Awaits – Reserve Now!"/>
            <Row className='scheduleForm'>
            <ContactForm/>
            </Row>
            </>
    )
}

export default ScheduleForm
import React, { useState } from 'react';
import {Form, Row, Col, Button} from 'react-bootstrap'
export default function ContactForm() {
  const [postData, setPostData] = useState({});
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');

  const handleContactUs = async () => {
    // Validate input fields
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setSuccessMessage(''); // Reset success message
      return;
    }

    try {
      const response = await fetch('https://api.jadeedmunshi.com/public/api/contact_us', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      });

      // Handle the response as needed
      if (response.ok) {
        console.log('Data posted successfully');
        setSuccessMessage('Data posted successfully');
        setPostData({}); // Clear form fields
        setErrors({}); // Clear errors
      } else {
        console.error('Failed to post data');
        setSuccessMessage('');
      }
    } catch (error) {
      console.error('Error posting data:', error);
      setSuccessMessage('');
    }
  };

  const validateFields = () => {
    const errors = {};

    // Validate each field
    if (!postData.first_name) {
      errors.first_name = 'First Name is required';
    }

    if (!postData.last_name) {
      errors.last_name = 'Last Name is required';
    }

    if (!postData.email || !isValidEmail(postData.email)) {
      errors.email = 'Please enter a valid email address';
    }

    if (!postData.phone) {
      errors.phone = 'Phone is required';
    }

    if (!postData.purpose) {
      errors.purpose = 'Subject is required';
    }

    if (!postData.message) {
      errors.message = 'Message is required';
    }

    return errors;
  };

  const isValidEmail = (email) => {
    // Use a regular expression to validate the email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <Form className='contactForm'>
      <Row className='align-items-start'>
        <Col xs={12} md={6} className="mb-2">
            <Form.Control 
                type="text"
                placeholder='First Name'
                value={postData.first_name || ''}
                onChange={(e) => {
                setPostData({ ...postData, first_name: e.target.value });
                setErrors({ ...errors, first_name: null });
                setSuccessMessage('');
                }} 
            />
            {errors.first_name && <p className='text-danger'>{errors.first_name}</p>}
        </Col>
        <Col xs={12} md={6} className="mb-2">
            <Form.Control
                type="text"
                placeholder='Last Name'
                value={postData.last_name || ''}
                onChange={(e) => setPostData({ ...postData, last_name: e.target.value })}
            />
            {/* Display validation errors */}
            {errors.last_name && <p className='text-danger'>{errors.last_name}</p>}
        </Col>
        <Col xs={12} md={6} className="mb-2">
            <Form.Control
                type="text"
                placeholder='Email'
                value={postData.email || ''}
                onChange={(e) => setPostData({ ...postData, email: e.target.value })}
            />
            {errors.email && <p className='text-danger'>{errors.email}</p>}
        </Col>
        <Col xs={12} md={6} className="mb-2">
            <Form.Control
                type="text"
                placeholder='Phone'
                value={postData.phone || ''}
                onChange={(e) => setPostData({ ...postData, phone: e.target.value })}
            />
            {errors.phone && <p className='text-danger'>{errors.phone}</p>}
        </Col>
        <Col xs={12} md={12} className="mb-2">
            <Form.Control
                type="text"
                placeholder='Subject'
                value={postData.purpose || ''}
                onChange={(e) => setPostData({ ...postData, purpose: e.target.value })}
            />
            {errors.purpose && <p className='text-danger'>{errors.purpose}</p>}
        </Col>
        <Col xs={12} md={12} className="mb-2">
            <Form.Control
                as="textarea" rows={3}
                placeholder='Message'
                value={postData.message || ''}
                onChange={(e) => setPostData({ ...postData, message: e.target.value })}
            />
            {errors.message && <p className='text-danger'>{errors.message}</p>}
        </Col>
        <Col xs={12} md={12} className="mb-2 text-center">
            <Button onClick={handleContactUs} variant="primary">Submit</Button>
        </Col>
        <Col xs={12} md={12} className="mb-2">
        {successMessage && <p className='text-success'>{successMessage}</p>}
        </Col>
      </Row>
    </Form>
  );
}

import { Container } from "react-bootstrap";
function Header2({ title }) {
  return (
    <section className="innerHeader">
      <Container fluid>
        <h1>{title}</h1>
      </Container>
    </section>
  );
}
export default Header2;

import Header from "./Header2";
import { Row, Col, Container, Button, Image } from "react-bootstrap";

import qmaticCounter from "../img/counter.png";
import Heading from "../Components/Heading";
function Counter() {
  return (
    <>
      <Header title="Qmatic Counter" />
      <div className="counter-display">
        <section className="justify-content-center text-center qmaticCounter">
          <Container>
            <Row className="aboutRow justify-content-center">
              <Heading title="What is" heading="Qmatic Counter Display" />
              <Col xs={12} md={6}>
                <p>
                  The Qmatic counter's wireless connectivity seamlessly links
                  with any WiFi-enabled device, including mobiles, facilitating
                  effortless connections.
                </p>
                </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs={12} md={4}>
                <Image
                  src={qmaticCounter}
                  alt="Queue Managment System"
                  caption="Queue Managment System"
                />
              </Col>
            </Row>
            <Row className="justify-content-center mt-3">
              <Col xs={12} md={8}>
              <ul>
                  <li><b>Seamless Connectivity:</b> Qmatic counters are equipped with open WiFi functionality, allowing effortless connection to any available WiFi network.</li>
                  <li><b>Ticket Display:</b> Instantly showcases ticket numbers, ensuring smooth customer flow and efficient service management.</li></ul>
              </Col>
              
            </Row>
          </Container>
        </section>
        
        <section>
          <Container>
            <Row className="justify-content-center align-items-center text-center">
              <Col xs={12} md={6}>
                <h2>Download Qmatic Counter Guide</h2>
                <Button variant="primary" href="/Downloads">Qmatic Counter Guide</Button>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </>
  );
}
export default Counter;

import React from 'react'
import { Row, Col, Button, Carousel, Container, } from "react-bootstrap";
import slideQmatic from "../img/slides/slideQmatic.png";
import tokenId from "../img/slides/tokenId.png";
import wiatingId from "../img/slides/wiatingId.png";
import QmaticCounter from "../img/slides/QmaticCounter.png";
import slide1Bg from "../img/slides/slideBg.png";
const HeaderSlider = () => {
  return (
    <>
      <Carousel slide={false}>
        <Carousel.Item>
          <img src={slide1Bg} className="App-logo" alt="Streamline Your Waiting Experience" caption='Streamline Your Waiting Experience' />
          <Carousel.Caption>
            <Row>
              <Col xs={12} md={6}>
                <h1>Streamline <span>Your Waiting</span> Experience</h1>
                <Button variant="primary" href="/guide">See How Qmatic Works</Button>
              </Col>
              <Col xs={12} md={6}>
                <img src={slideQmatic} className="App-logo" alt="Turning Waiting into Winning"caption='Turning Waiting into Winning' />
              </Col>
            </Row>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img src={slide1Bg} className="App-logo" alt="Qmatic Counter Wireless Conectivity" caption='Qmatic Counter Wireless Conectivity' />
          <Carousel.Caption>
            <Row>
              <Col xs={12} md={6}>
                <h1>Qmatic <span>Counter</span> Wireless Conectivity</h1>
                <Button variant="primary" href="/Counter">See How Qmatic Counter Works</Button>
              </Col>
              <Col xs={12} md={6}>
                <img src={QmaticCounter} className="App-logo" alt="Qmatic Counter Wireless Conectivity"caption='Qmatic Counter Wireless Conectivity' />
              </Col>
            </Row>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
        <img src={slide1Bg} className="App-logo" alt="Elevate Your Business with Intelligent Queue Management" caption='Elevate Your Business with Intelligent Queue Management'/>
          <Carousel.Caption>
            <Row>
              <Col xs={12} md={6}>
                <h1>Turning <span>Waiting into</span> Winning</h1>
                <Button variant="primary" href="/guide">See How Qmatic Works</Button>
              </Col>
              <Col xs={12} md={6}>
                <img src={tokenId} className="App-logo" alt="First slide" />
              </Col>
            </Row>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
        <img src={slide1Bg} className="App-logo" alt="First slide" />
          <Carousel.Caption>
            <Row>
              <Col xs={12} md={6}>
                <h1>Elevate Your <span>Business with Intelligent</span> Queue Management</h1>
                <Button variant="primary" href="/guide">See How Qmatic Works</Button>
              </Col>
              <Col xs={12} md={6}>
                <img src={wiatingId} className="App-logo" alt="First slide" />
              </Col>
            </Row>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </>
  )
}

export default HeaderSlider

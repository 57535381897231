import React from 'react'

const Heading = ({title, heading}) => {
  return (
    <div className='sectionHeading'>
      <p>{title}</p>
      <h2>{heading}</h2>
    </div>
  )
}

export default Heading

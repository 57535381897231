import { Navbar, Nav, Container, Button } from "react-bootstrap";
import { React, useState } from "react";
import { NavLink } from "react-router-dom";
window.addEventListener("scroll", function () {
  let navbar = document.querySelector("nav");
  if (window, scrollY > 0) {
    navbar.classList.add('navbgOnScroll');
  }else{
    navbar.classList.remove('navbgOnScroll');
  }
});
function Header() {
  return (
    <Navbar expand="lg" fixed="top">
      <Container>
        <Navbar.Brand href="/">
          <a href="/">
            <h3>Qmatic</h3>
          </a>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <NavLink to="/">Home</NavLink>
            {/* <NavLink to="/aboutUs">About Us</NavLink> */}
            {/* <NavLink to="/qmatic">Why Qmatic</NavLink> */}
            <NavLink to="/solutions">Solutions</NavLink>
            <NavLink to="/Counter">Qmatic Counter</NavLink>
            <NavLink to="/videos">Videos</NavLink>
            <NavLink to="/guide">Guide</NavLink>
            <NavLink to="/clients">Our Clients</NavLink>
            {/* <NavLink to="/contactUs">Contact US</NavLink> */}
            <NavLink to="/demo">Schedule A Demo</NavLink>
            {/* <NavLink to="/demo" className="active">Schedule A Demo</NavLink> */}
            <NavLink to="/Downloads" className="btn btn-sm btn-primary">Downloads</NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
export default Header;

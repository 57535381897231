import { Button, Container,Row,Col } from "react-bootstrap";
import aboutQmatic from "../img/aboutQmatic.png";
import Heading from "../Components/Heading";
function AboutQmatic() {
  return (
    <Container>
      <Row className="aboutRow">
        <Col xs={12} md={6}>
          <Heading title="10+ year of Experience" heading="About Qmatic" />
          <p>
            Our Queue Management System (QMS) is a set of software and hardware
            solutions designed to efficiently manage and organize queues of
            people or tasks in various settings. QMS is used to streamline
            customer service, enhance operational efficiency, and improve the
            overall customer experience in places such as retail stores,
            government offices, healthcare facilities, banks, airports, and many
            other service-oriented organizations.
          </p>
          <Button variant="primary" href="/aboutUs">More About Qmatic</Button>
        </Col>
        <Col xs={12} md={6}>
          <img src={aboutQmatic} className="" alt="Queue Managment System"caption='Queue Managment System'/>
        </Col>
      </Row>
    </Container>
  );
}

export default AboutQmatic;

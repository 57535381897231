import React from 'react';
import { Container, Row, Col, Card, Badge } from 'react-bootstrap';
import Heading from '../Components/Heading';
import asimKhan from '../img/testimonials/asimKhan.jpg';
import ishfaqAhmadani from '../img/testimonials/ishfaqAhmadani.jpg';
import munaqarDGK from '../img/testimonials/munaqarDGK.jpg';
import shahidLayyah from '../img/testimonials/shahidLayyah.jpg';
import zulfiqarAli from '../img/testimonials/zulfiqarAli.jpg';
import clientThumb from '../img/testimonials/clientThumb.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';

function Testimonials() {
  const testimonial = [
    {
      'name':'Muhammad Asim Khan',
      'designation':'Multan District Police',
      'description':'We are delighted to use the this QueueMatic system, as it significantly contributes to the efficiency and organization of our processes.',
      'color':'secondary',
      'thumb':asimKhan,
    },
      {
      'name':'Muhammad Munawar',
      'designation':'Muzaffargarh District Police',
      'description':"We're pleased with the QMatic system—excellent software, reliable performance, contributing to operational success.",
      'color':'danger',
      'thumb':munaqarDGK,
    },
      {
      'name':'Ishfaq Ahmadani',
      'designation':'DGK District Police',
      'description':"The system efficiently manages customer flow, reducing wait times and enhancing the overall experience for everyone involved.",
      'color':'warning',
      'thumb':ishfaqAhmadani,
    },
      {
      'name':'Muhammad Shahid',
      'designation':'Layyah District Police',
      'description':'The QMatic system is highly regarded as excellent software, providing efficient and reliable solutions for our needs. Thumbs Up',
      'color':'info',
      'thumb':shahidLayyah,
    },
      {
      'name':'Wajid Hussain',
      'designation':'Bhakkar District Police',
      'description':"We highly value the QMatic system for streamlining our processes. Its user-friendly interface & efficiency bring us great satisfaction.",
      'color':'success',
      'thumb':clientThumb,
    },
      {
      'name':'Zulfiqar Ali',
      'designation':'Okara District Police',
      'description':'The system minimizes wait times, easing visitor frustration. Its analytics optimize staff scheduling for peak hours.',
      'color':'primary',
      'thumb':zulfiqarAli,
    }
  
  ];

  return (
    <section className='testimonials bg-light-blue'>
      <Container>
        <div className='text-center'>
          <Heading title='Testimonials' heading="Inspiring Stories of Client Satisfaction" />
        </div>
        <Row className='mt-5'>
          {testimonial.map((testimonial, index) => (
            <Col xs={12} md={4} className='mt-4' key={index}>
              <Card>
                <Card.Body>
                  <Badge bg={testimonial.color}><FontAwesomeIcon className="icon" icon={faQuoteLeft} /></Badge>
                  <Card.Text>
                    {testimonial.description}
                  </Card.Text>
                  <div className="testimonial">
                    <Card.Img src={testimonial.thumb} />
                    <div className="title">
                      <h6>{testimonial.name}</h6>
                      <p><small>{testimonial.designation}</small></p>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}

export default Testimonials;

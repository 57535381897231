import { Tabs, Tab, Card, Row, Col, Button, Carousel, Container, Image } from "react-bootstrap";
import Header from '../Components/Header2';
import UserFlowEnglish from "../img/QmaticUserFlowEnglish.svg";
import UserFlowUrdu from "../img/QmaticUserFlowUrdu.svg";
import CounterGuide from "./CounterGuide";
function Guide() {
  return (
    <>
    <Header title="Guide"/>
    <section className="bg-light-blue">
      <Container>
    <Tabs
      defaultActiveKey="English"
      id="justify-tab-example"
      className="mb-3"
      justify
    >
      <Tab eventKey="English" title="English Guide">
       <Image src={UserFlowEnglish} fluid alt="How to Use"/>
      </Tab>
      <Tab eventKey="Urdu" title="Urdu Guide">
        <Image src={UserFlowUrdu} fluid alt="How to Use"/>
      </Tab>
      <Tab eventKey="Video" title="Counter Guide">
        <CounterGuide/>
      </Tab>
    </Tabs>
      
    </Container>
    </section>
    </>
  );
}

export default Guide;
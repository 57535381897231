import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
function Footer() {
  return (
    <div className="footer">
      <div className="footer-links">
        <Container>
          <Row>
            <Col xs={12} md={3}>
              <h3>Qmatic</h3>
              <p>
                Our Queue Management System (QMS) is a set of software and
                hardware solutions designed to efficiently manage and organize
                queues of people or tasks in various settings. QMS is used to
                streamline customer service, enhance operational efficiency, and
                improve the overall customer experience in places such as retail
                stores, government offices, healthcare facilities, banks,
                airports, and many other service-oriented organizations.
              </p>
            </Col>
            <Col xs={12} md={3}>
              <h3>Services</h3>
              <ul>
                <li><NavLink to="/services">Queue Creation and Management</NavLink></li>
                <li><NavLink to="/services">Ticketing System</NavLink></li>
                <li><NavLink to="/services">Customer Registration</NavLink></li>
                <li><NavLink to="/services">Multi-Channel Integration</NavLink></li>
                <li><NavLink to="/services">Staff Management</NavLink></li>
                <li><NavLink to="/services">More</NavLink></li>
              </ul>
            </Col>
            <Col xs={12} md={3}>
              <h3>Quick Links</h3>
              <ul>
                <li><NavLink to="/">Home</NavLink></li>
                <li><NavLink to="/aboutUs">About Us</NavLink></li>
                <li><NavLink to="/qmatic">Why Qmatic</NavLink></li>
                <li><NavLink to="/guide">Guide</NavLink></li>
                <li><NavLink to="/clients">Our Clients</NavLink></li>
                <li><NavLink to="/contactUs">Contact US</NavLink></li>
              </ul>
            </Col>
            <Col xs={12} md={3}>
              <h3>Solutions</h3>
              <ul>
                <li><NavLink to="/solutions">Retail</NavLink></li>
                <li><NavLink to="/solutions">Healthcare</NavLink></li>
                <li><NavLink to="/solutions">Education</NavLink></li>
                <li><NavLink to="/solutions">Events</NavLink></li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer-copyright">
        <Container className="text-center">
          <p>Copyright © 2010-2023 Qmatic. All rights reserved.</p>
        </Container>
      </div>
    </div>
  );
}

export default Footer;

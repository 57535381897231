import Header from '../Components/Header2';
import QmaticDetail from '../Components/QmaticDetail';
import Clients from '../Components/ClientsSlider';
import Testimonials from '../Components/Testimonials';
import AboutQmatic from '../Components/AboutQmatic';
import WhyUs from "../Components/WhyUs";

function AboutUs() {
  return (
    <>
    <Header title="About Us"/>
    <section>
        <AboutQmatic />
      </section>
      <section className="bg-light-blue">
        <QmaticDetail />
      </section>
      <section>
        <WhyUs />
      </section>

<Testimonials/>
<Clients/>
    </>
  );
}

export default AboutUs;
import {
  Row,
  Col,
  Button,
  Carousel,
  Container,
  Card,
  Image,
} from "react-bootstrap";
import Header from "../Components/Header2";
import Clients from "../Components/ClientsSlider";
// images
import retailSolution from "../img/solutions/retailSolution.png";
import healthCareSolution from "../img/solutions/healthCareSolution.png";
import educationSolution from "../img/solutions/educationSolution.png";
import eventSolution from "../img/solutions/eventSolution.png";

function Solutions() {
  return (
    <>
      <Header title="Solutions" />
      <section>
        <Container>
          <Row className="justify-content-md-center solutions align-item-center">
            <Col xs={12} md={8}>
              <Row>
                <Col xs={12} md={6}>
                  <h3>Retail</h3>
                  <p>
                    Discover the future of retail efficiency with our
                    cutting-edge Queue Management System. Say goodbye to long,
                    frustrating lines and hello to a seamless shopping
                    experience. With our system, you can streamline customer
                    flow, reduce wait times, and enhance overall satisfaction.
                    Revolutionize your retail operation today and elevate your
                    customer service to new heights with our Queue Management
                    System.
                  </p>
                </Col>
                <Col xs={6} md={6}>
                  <Image src={retailSolution} thumbnail />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="bg-light-blue">
        <Container>
          <Row className="justify-content-md-center solutions align-item-center">
            <Col xs={12} md={8}>
              <Row>
                <Col xs={6} md={6}>
                <Image src={healthCareSolution} thumbnail />
                </Col>
                <Col xs={12} md={6}>
                  <h3>Healthcare</h3>
                  <p>
                    Unlock the potential of efficient healthcare service
                    delivery with our advanced Queue Management System. Bid
                    farewell to crowded waiting rooms and hello to a more
                    organized, patient-centric approach. Our system empowers
                    healthcare providers to optimize patient flow, minimize wait
                    times, and ultimately improve the quality of care. Elevate
                    your healthcare facility's performance and patient
                    satisfaction by implementing our Queue Management System
                    today.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row className="justify-content-md-center solutions align-item-center">
            <Col xs={12} md={8}>
              <Row>
                <Col xs={12} md={6}>
                  <h3>Education</h3>
                  <p>
                    Transform the way your educational institution operates with
                    our state-of-the-art Queue Management System. Wave goodbye
                    to chaotic lines and usher in a smoother, more efficient
                    educational experience. Our system empowers educators to
                    manage student queues, reduce waiting times, and enhance
                    overall productivity. Elevate your school or university's
                    efficiency and student satisfaction by adopting our Queue
                    Management System today.
                  </p>
                </Col>
                <Col xs={6} md={6}>
                <Image src={educationSolution} thumbnail />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="bg-light-blue">
        <Container>
          <Row className="justify-content-md-center solutions align-item-center">
            <Col xs={12} md={8}>
              <Row>
                <Col xs={6} md={6}>
                <Image src={eventSolution} thumbnail />
                </Col>
                <Col xs={12} md={6}>
                  <h3>Eventes</h3>
                  <p>
                    Elevate your event planning with our cutting-edge Queue
                    Management System. Bid farewell to frustrating queues and
                    say hello to a seamless event experience. Our system
                    empowers event organizers to streamline attendee flow,
                    reduce wait times, and enhance overall guest satisfaction.
                    Make your event stand out and ensure a memorable experience
                    for all attendees by utilizing our Queue Management System
                    today.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <Clients />
    </>
  );
}

export default Solutions;

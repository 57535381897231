import { Row, Col, Button, Carousel, Container, } from "react-bootstrap";
import React from "react";
import CustomCard from "../Components/CustomCard";
import QmaticDetail from "../Components/QmaticDetail";
import Clients from "../Components/ClientsSlider";
import Testimonials from "../Components/Testimonials";
import AboutQmatic from "../Components/AboutQmatic";
import ScheduleForm from "../Components/ScheduleForm";
import WhyUs from "../Components/WhyUs";
import HeaderSlider from "../Components/HeaderSlider";
import Heading from "../Components/Heading";
import {
  faUserFriends,
  faTicket,
  faAddressCard,
  faComputer,
  faUserCog,
  faUsersCog,
  faChartBar,
  faCommentDots,
} from "@fortawesome/free-solid-svg-icons";
function Home() {
  return (
    <>
      <HeaderSlider/>
      <section>
        <AboutQmatic />
      </section>
      <section>
        <Container>
          <div className="row text-center ">
            <Heading title="Our Services" heading="Services For Your Domain" />
          </div>
          <div className="row">
            <CustomCard
              icon={faUserFriends}
              title="Creation and Management"
              bodyContent="QMS allows you to create and manage multiple queues or lines for different services or departments within an organization. It ensures that customers are directed to the right queue based on their needs."
            />
            <CustomCard
              icon={faTicket}
              title="Queue Ticketing System"
              bodyContent="Many QMS solutions include a ticketing system that assigns a unique ticket number to each customer when they arrive. Customers can monitor their position in the queue and receive notifications when it's their turn."
            />
            <CustomCard
              icon={faAddressCard}
              title="Customer Registration"
              bodyContent="Some QMS systems offer customer registration capabilities, enabling customers to input their information or service request details at a self-service kiosk or online before joining the queue."
            />
            <CustomCard
              icon={faUserCog}
              title="Multi-Channel Integration"
              bodyContent="Integration with various communication channels like websites, mobile apps, SMS, or voice calls allows customers to check queue status, join a queue remotely, or receive notifications about their queue progress."
            />
            <CustomCard
              icon={faUsersCog}
              title="Staff Management"
              bodyContent="QMS can help organizations allocate and manage staff resources efficiently by monitoring queue lengths and adjusting staffing levels in real-time."
            />
            <CustomCard
              icon={faComputer}
              title="Digital Signage"
              bodyContent="Digital displays or signage screens can be used to show queue information, estimated wait times, and announcements to keep customers informed."
            />
            <CustomCard
              icon={faChartBar}
              title="Analytics, Reporting"
              bodyContent="Queue Management Systems often include reporting and analytics tools that help organizations analyze queue data, customer waiting times, and service efficiency to make data-driven decisions for improvement."
            />
            <CustomCard
              icon={faCommentDots}
              title="Customer Feedback"
              bodyContent="Some QMS solutions allow customers to provide feedback and ratings after their service is complete, providing valuable insights for quality improvement."
            />
          </div>
          <div className="row text-center mt-3">
            <div>
              <Button variant="primary" href="/services">View More</Button>
            </div>
          </div>
        </Container>
      </section>
      <section className="bg-light-blue">
        <QmaticDetail />
      </section>
      <section>
        <WhyUs />
      </section>
      <Testimonials />
      <section className="demo-section">
        <Container>
          <Row>
            <Col xs={12} md={{ span: 5, offset: 7 }} className="demo-form">
              <ScheduleForm />
            </Col>
          </Row>
        </Container>
      </section>
      <Clients />
    </>
  );
}

export default Home;

import Header from "./Header2";
import { Container} from "react-bootstrap";
import GuideVIdeos from "./GuideVIdeos";
function Videos() {
  return (
    <>
      <Header title="Videos" />
      <section>
        <Container>
          <GuideVIdeos/>
        </Container>
      </section>
    </>
  );
}
export default Videos;
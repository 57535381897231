import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Home from './Components/Home';
import AboutUs from './Components/AboutUs';
import Services from './Components/Services';
import Qmatic from './Components/Qmatic';
import Solutions from './Components/Solutions';
import Clients from './Components/Clients';
import Guide from './Components/Guide';
import ContactUs from './Components/ContactUs';
import Downloads from './Components/Downloads';
import Demo from './Components/ScheduleDemo';
import Videos from './Components/Videos';
import Counter from './Components/Counter';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname]);

  return null;
}

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/qmatic" element={<Qmatic />} />
          <Route path="/solutions" element={<Solutions />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/videos" element={<Videos />} />
          <Route path="/counter" element={<Counter />} />
          <Route path="/guide" element={<Guide />} />
          <Route path="/contactUs" element={<ContactUs />} />
          <Route path="/Downloads" element={<Downloads />} />
          <Route path="/demo" element={<Demo />} />
        </Routes>
        <ScrollToTop />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;

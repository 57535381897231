import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
function CustomCard({icon,title, bodyContent}) {
    return (
        <div className='col-md-3 mt-4 service-item'>
            <Card>
                <Card.Body>
                    <FontAwesomeIcon className="icon" icon={icon} />
                    <Card.Title> {title}</Card.Title>
                    <Card.Text>
                        {bodyContent}
                    </Card.Text>
                    <div className='details'>
                        <Card.Title> {title}</Card.Title>
                        <Card.Text>
                            {bodyContent}
                        </Card.Text>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}

export default CustomCard;
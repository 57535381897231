import Header from "../Components/Header2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faMapLocation,
  faMapPin,
  faEnvelope,
  faHeadphonesAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  Row,
  Col,
  Button,
  Carousel,
  Container,
  Image,
} from "react-bootstrap";
import ScheduleForm from "../Components/ScheduleForm";
function ScheduleDemo() {
  return (
    <>
      <Header title="Sechdule A Demo" />
      <section>
        <Container>
          <Row className="contact-form-main  justify-content-md-center align-items-center">
            <Col xs={12} md={5}>
              <h5>Quick Response to Your Query</h5>
              <ul>
                <li>
                  <FontAwesomeIcon className="icon" icon={faPhone} />{" "}
                  <span>
                    +92 326 0667666, <br></br>+92 300 8639171
                  </span>
                </li>
                <li>
                  <FontAwesomeIcon className="icon" icon={faEnvelope} />{" "}
                  <span>info@qmatic.com</span>
                </li>
                <li>
                  <FontAwesomeIcon className="icon" icon={faHeadphonesAlt} />{" "}
                  <span>sales@qmatic.com</span>
                </li>
                <li>
                  <FontAwesomeIcon className="icon" icon={faMapLocation} />{" "}
                  <span>327-F, Shah Ruken-E-Alam Colony, Multan</span>
                </li>
              </ul>
            </Col>
            <Col xs={12} md={5} className="demo-form">
              <ScheduleForm />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
export default ScheduleDemo;

import React from "react";
import Header from "../Components/Header2";
import {
  ButtonGroup,
  Row,
  Col,
  Button,
  Container
} from "react-bootstrap";
import { saveAs } from "file-saver";
import printerexe from "../printerDrivers/BlackCopperBC-85ACPrinterDriverV1.0.zip";

import qmaticCounterSoftware from "../counterSoftware/QmaticCounterSoftware.rar";
import doorlockguide from "../img/doorlockguide.jpg";
import questions from "../Qmatic-Important-Questions.pdf";

// Guide
import psaGuide from "../PSA-Guide.pdf";
import counterGuideOld from "../Counter-Guide-Old.pdf";
import counterGuideNew from "../Counter-Guide-New.pdf";
import qmaticGuideComplete from "../Qmatic-Guide-Complete.pdf";
import qmaticMachineGuide from "../Qmatic-Machine-Guide.pdf";


const Downloads = () => {
  const downloadImage = () => {
    saveAs(doorlockguide, "doorlockguide.jpg");
  };
  const qmaticMachineCounterQuestions = () => {
    saveAs(questions, "Qmatic-Important-Questions.pdf");
  };
  
  // Guide
  const downloadpsaguide = () => {
    saveAs(psaGuide, "PSA-Guide.pdf");
  };
  const downloadcounterguideold = () => {
    saveAs(counterGuideOld, "Counter-Guide-Old.pdf");
  };
  const downloadcounterguidenew = () => {
    saveAs(counterGuideNew, "Counter-Guide-New.pdf");
  };
  const downloadqmaticcompleteguide = () => {
    saveAs(qmaticGuideComplete, "Qmatic-Guide-Complete.pdf");
  };
  const downloadqmaticMachineGuide = () => {
    saveAs(qmaticMachineGuide, "Qmatic-Machine-Guide.pdf");
  };

  const buttonConfig = [
    { href: printerexe, variant: "primary", text: "Thermal Printer Driver" },
    { onClick: downloadImage, variant: "info", text: "Door Lock Guide" },
    { href: qmaticCounterSoftware, variant: "secondary", text: "Qmatic Counter Software" },

    

// Giide
    { onClick: downloadpsaguide, variant: "primary", text: "PSA Guide" },
    { onClick: downloadcounterguideold, variant: "danger", text: "Counter Guide Old 888" },
    { onClick: downloadcounterguidenew, variant: "primary", text: "Counter Guide New" },
    { onClick: downloadqmaticcompleteguide, variant: "success", text: "Qmatic Complete Guide" },
    { onClick: downloadqmaticMachineGuide, variant: "primary", text: "Qmatic Machine Guide" },


    { onClick: qmaticMachineCounterQuestions, variant: "danger", text: "Questions About Qmatic Machine and Counter" },
  ];

  return (
    <div>
      <Header title="Downloads" />
      <section className="text-center">
        <Container>
          <h3>Click on Button and Download File</h3>
          <Row className="justify-content-center mt-5">
            <Col xs={12} md={12} className="download-btn-group">
              {buttonConfig.map((button, index) => (
                <Button
                  key={index}
                  href={button.href}
                  onClick={button.onClick}
                  variant={button.variant}
                  className="mb-3"
                >
                  {button.text}
                </Button>
              ))}
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Downloads;

import React from 'react'
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDonate  } from '@fortawesome/free-solid-svg-icons';
import CustomCard from './CustomCard';
import Heading from './Heading';
export default function WhyUs() {
  return (
    <div>
      <Container className='whyus'>
            <div className='row'>
                <Row>
                    <Col xs={12} md={6}>
                        <Heading title='Why Choose Us' heading="Transforming Wait Times into Quality Time"/>
                        <p>It is a compelling concept that centers on turning what is traditionally seen as an inconvenience – waiting – into a positive and valuable experience for customers.</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12}>
                        <Row className='mb-3'>
                        <CustomCard icon={faDonate}
                            title="Customer Feedback"
                            bodyContent="Some QMS solutions allow customers to provide feedback and ratings after their service is complete, providing valuable insights for quality improvement."
                        />
                        <CustomCard icon={faDonate}
                            title="Customer Feedback"
                            bodyContent="Some QMS solutions allow customers to provide feedback and ratings after their service is complete, providing valuable insights for quality improvement."
                        />
                        <CustomCard icon={faDonate}
                            title="Customer Feedback"
                            bodyContent="Some QMS solutions allow customers to provide feedback and ratings after their service is complete, providing valuable insights for quality improvement."
                        />
                        </Row>
                        <Button href="/services" variant="primary">View More</Button>
                    </Col>
                </Row>
                
            </div>
        </Container>
    </div>
  )
}

import { Button, Container, Row, Col } from "react-bootstrap";
function QmaticDetail() {
  return (
    <>
      <Container className="text-center cta">
        <Row className="justify-content-center">
          <Col md={{ span: 8 }}>
            <h1>
              Streamlining Waiting, <span>Elevating</span> Service
            </h1>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={{ span: 5 }}>
            <p>
              a powerful concept that revolves around enhancing the overall
              customer experience by focusing on two key elements: minimizing
              wait times and elevating the quality of service.
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={{ span: 8 }}>
            <Button href="/demo" variant="primary">Get Free Consultation</Button>{" "}
            <Button href="tel:+923260667666" variant="info">Call +92-326-0667666</Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default QmaticDetail;